import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root"
})
export class SellerService {
    baseUrl = environment.baseUrl;

    constructor(private http: HttpClient) { }

    uploadContentFile(file) {
        return this.http.post(`${this.baseUrl}/api/uploadcontent`, file);
    }
    uploadImagesContent(file) {
        return this.http.post(`${this.baseUrl}/api/multiuploadcontent`, file);
    }
    uploadFilesContent(file, progress) {
        return this.http.post(`${this.baseUrl}/api/multiuploadcontent`, file, progress);
    }
    uploadVideoFile(file, progress) {
        return this.http.post(`${this.baseUrl}/api/uploadcontent`, file, progress);
    }
    uploadContentFileForContent(file) {
        return this.http.post(`${this.baseUrl}/api/uploadcontent`, file);
    }
    addSellerContent(content) {
        return this.http.post(`${this.baseUrl}/api/addcontent`, content);
    }
    updateSellerContent(content) {
        return this.http.post(`${this.baseUrl}/api/updateContent`, content);
    }
    getLibraryContent(sellerId, contentId, live) {
        return this.http.get(`${this.baseUrl}/api/contentlist?uid=${sellerId}&id=${contentId}&live=${live}`);
    }
    getSellerContent(contentId) {
        return this.http.get(`${this.baseUrl}/api/contentlist?uid=${contentId}`);
    }
    getSellerContentList(sellerId) {
        return this.http.get(`${this.baseUrl}/api/contentlist?uid=${sellerId}`);
    }
    getSellerContentListLive(sellerId, live) {
        return this.http.get(`${this.baseUrl}/api/contentlist?uid=${sellerId}&live=${live}`);
    }
    getAllContentList() {
        return this.http.get(`${this.baseUrl}/api/contentlist`);
    }

    // Offers
    getOffersList(sellerId: number, content_id: number = null, offer_id: number = null) {
        return this.http.get(`${this.baseUrl}/api/getOfferByCustomer?uid=${sellerId}&content_id=${content_id}&offer_id=${offer_id}`);
    }

    // Categories
    getCategoriesList() {
        return this.http.get(`${this.baseUrl}/api/getcategory`);
    }
    // Buyer search
    buyerTagsList(tag) {
        return this.http.post(`${this.baseUrl}/api/getTag`, tag);
    }
    //Seller search
    sellerTagsList(tag) {
        return this.http.post(`${this.baseUrl}/api/getTag`, tag);
    }
    sellerContentListUid(uid): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/getBuyeroffer?uid=${uid}`);
    }
    //deletecontent
    deleteContent(tag) {
        return this.http.post(`${this.baseUrl}/api/deleteContent`, tag);
    }
    //notifications
    getNotifications(receiver_id): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/totalunread?receiver_id=${receiver_id}`);
    }

    getBuyerNotiCount(receiver_id): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/total-unread-count?receiver_id=${receiver_id}`);
    }

    //CsvFileUpload
    addSellerCsvContent(val) {
        return this.http.post(`${this.baseUrl}/api/uploadcsvcontent`, val);
    }


    // // hit api after submit csv
    callAddBulkMetadataCsv() {
        return this.http.get(`${this.baseUrl}/api/csvupload_get`,);
    }
    // // hit api after submit updatecsv
    callAddBulkMetadataUpdateCsv() {
        return this.http.get(`${this.baseUrl}/api/csvupload_update`,);
    }
    // get api seller video trailer
    getSellerMediaList(sellerId, type): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/getsellermedia?uid=${sellerId}&media_type=${type}`,);
    }
    //mapping api
    mappingContentFile(file) {
        return this.http.post(`${this.baseUrl}/api/contentmap`, file);
    }
    // delete mapping content api
    deleteContentFile(file) {
        return this.http.post(`${this.baseUrl}/api/contentdelete`, file);
    }
    //unmapping content api
    unmappingContentFile(file) {
        return this.http.post(`${this.baseUrl}/api/contentunmap`, file);
    }
    // webseries upload
    uploadContentFileForSeries(data) {
        return this.http.post(`${this.baseUrl}/api/webseason`, data);
    }
    // episode upload
    uploadContentFileForEpisode(data) {
        return this.http.post(`${this.baseUrl}/api/webepisode`, data);
    }

    //   upload File for Series
    uploadFileForSeries(data) {
        return this.http.post(`${this.baseUrl}/api/imgupload`, data);
    }
    //   get Offer List
    getAllOfferList(seller_id): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/getAllSubmitOffer?seller_id=${seller_id}`);
    }
    //   Season get
    getSellerAllSeason(content_id, seller_id): Observable<any> {
        return this.http.get(`${this.baseUrl}/api/allseason?content_id=${content_id}&seller_id=${seller_id}`)
    }
    // episode get 
    getSellerAllEpisode(content_id, seller_id, season) {
        return this.http.get(`${this.baseUrl}/api/allepisode?content_id=${content_id}&seller_id=${seller_id}&season=${season}`)
    }

    //   resubmit Offer Page
    resubmitOfferPage(data) {
        return this.http.post(`${this.baseUrl}/api/resubmitoffer`, data);
    }

    //   Offer history
    offerHistoryPage(uid, offer_id) {
        return this.http.get(`${this.baseUrl}/api/offerhistory?offer_id=${offer_id}&uid=${uid}`);
    }

    //   accept Offer
    acceptOffer(seller_id, offer_id) {
        return this.http.get(`${this.baseUrl}/api/acceptoffer?offer_id=${offer_id}&uid=${seller_id}`);
    }
    //Contact Us 
    contactUSForm(data) {
        return this.http.post(`${this.baseUrl}/api/get-in-touch`, data);
    }
    // trending Content
    addHitListData(id) {
        return this.http.post(`${this.baseUrl}/api/updatedtrendingcontent`, id);
    }
    // Add WatchList
    addWatchlistData(data) {
        return this.http.post(`${this.baseUrl}/api/add-watch-list`, data);
    }

    // get WatchList Data
    getWatchListData(seller_id) {
        return this.http.get(`${this.baseUrl}/api/get-watch-list?id=${seller_id}`);
    }
    // Delete Watch List
    deleteWatchList(tag) {
        return this.http.post(`${this.baseUrl}/api/deletewatchlistcontent`, tag);
    }

    //  check Library Data
    getLibraryDataCheck(uid) {
        return this.http.get(`${this.baseUrl}/api/getlibraryalldata?uid=${uid}`);
    }
    // getLibrary Data
    getLibraryDataList(uid, type) {
        return this.http.get(`${this.baseUrl}/api/getlibrarycontent/?uid=${uid}&content_type=${type}`);
    }

    // add Library Data
    addLibraryDataList(data) {
        return this.http.post(`${this.baseUrl}/api/addlibrarycontent/`, data);
    }
    //   upload library videos
    uploadLibrarytrailer(file) {
        return this.http.post(`${this.baseUrl}/api/uploadlabcontent`, file);
    }
    // Update Library
    updateLibraryDataList(file){
        return this.http.post(`${this.baseUrl}/api/updatelibrarydata`, file);
    }
    // get Library All data
    getLibraryAllDataList(uid){
        return this.http.get(`${this.baseUrl}/api/getlibrarydata?uid=${uid}`);
    }
    // 
    deleteLibrary(id){
        return this.http.get(`${this.baseUrl}/api/deletelibrarydata/?id=${id}`); 
    }

    getOMDBSearchYearApiData(x:any,y:any,z:any){
        return this.http.get(`https://www.omdbapi.com/?apikey=5dfdedb&s=${x}&y=${y}&type=${z}`); 
    }

    getOMDBApiDataByID(id:any){
        return this.http.get(`https://www.omdbapi.com/?apikey=5dfdedb&i=${id}`); 
    }
    
}