import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, NgForm, Validators, FormControl } from "@angular/forms";
import { Router } from "@angular/router";
import { SellerService } from "src/app/core/services/seller.service";
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { FetchDataService } from 'src/app/core/services/fetch-data.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {

  constructor(private router: Router,private modalService: NgbModal) { }

  user;
  type;extra = true;

  ngOnInit(): void {
  }

  /**
   * Open small modal
   * @param largeDataModal small modal data
   */

  smallModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'lg', centered: true });
  }
  logout() {

    this.router.navigate(['/account/login']);
    localStorage.clear();
  }
  dashboard() {
    this.user = JSON.parse(localStorage.getItem('clpUser')).type
    if (this.user == 1) {
      this.router.navigate(['/buyer/marketplace']);
    } else {
      this.router.navigate(['/seller/contentlist']);
    }

  }

}
