<body data-spy="scroll" data-target=".fixed-top">
    <!-- Navigation -->
    <nav class="navbar navbar-expand-lg fixed-top navbar-light">
        <div class="container">

            <!-- Text Logo - Use this if you don't have a graphic logo -->
            <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Kora</a> -->

            <!-- Image Logo -->
            <a class="navbar-brand logo-image" href="">
                <img src="assets/images/xchange.png" alt="alternative"></a>

            <button class="navbar-toggler p-0 border-0" type="button" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="true" aria-label="toggle navigation" data-toggle="collapse">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse" id="navbarsExampleDefault">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('header')">Home <span class="sr-only">(current)</span></a>
                    </li>
                    
                    <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('intro')">Intro</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('features')">Features</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link page-scroll"   (click)="gotoScroll('details')">Details</a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/sale-support" class="nav-link page-scroll"  href="javascript.void(0)" *ngIf="!user"   >Sales Support </a>
                    </li>
                    <li class="nav-item">
                        <a routerLink="/faqs" class="nav-link page-scroll"  href="javascript.void(0)" *ngIf="!user"   >FAQ's</a>
                    </li>
                    <!-- <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" id="dropdown01" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">Drop</a>
                        <div class="dropdown-menu" aria-labelledby="dropdown01">
                            <a class="dropdown-item page-scroll" href="article.html">Article Details</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item page-scroll" href="terms.html">Terms Conditions</a>
                            <div class="dropdown-divider"></div>
                            <a class="dropdown-item page-scroll" href="privacy.html">Privacy Policy</a>
                        </div>
                    </li> -->
                     <li class="nav-item" *ngIf="this.extra" >
                            <a routerLink="/account/register-email" class="btn-solid-sm page-scroll" href="">Signup</a>
                            <a  routerLink="/account/login"  class="btn-solid-sm page-scroll" href="">Login</a>
                     </li>
                     <li class="nav-item" *ngIf="!this.extra"  >
                        <a  routerLink=""   class="btn-solid-sm page-scroll" (click)="dashboard()">Go to Content List</a>
                        <a routerLink="/account/login"   class="btn-solid-sm page-scroll" (click)="logout()">Logout</a>
                     </li>
                </ul>
               
                <!-- <span class="nav-item" *ngIf="user">
                    <a routerLink="/{{ (user.type == 1) ? 'buyer' : 'seller'}}" class="btn-solid-sm page-scroll" href="">Dashboard</a>
                </span> -->
            </div> <!-- end of navbar-collapse -->
        </div> <!-- end of container -->
    </nav> <!-- end of navbar -->
    <!-- end of navigation -->


    <!-- Header -->
    <header id="header" class="header">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="text-container">
                        <h1 class="h1-large" style="font-size: 2.5rem;;">Blockchain powered Video & Audio Content
                            Rights Marketplace</h1>
                        <p class="p-large">World’s only Assisted sales platform for Digital Media Content Rights  </p>
                        <a routerLink="/account/register-email"  class="btn-solid-lg">Sign up</a>
                        <!-- <a class="btn-outline-lg page-scroll" href="#intro">Discover</a> -->
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-7">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/Video-&-Audio-Content.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </header> <!-- end of header -->
    <!-- end of header -->


    <!-- Customers -->
    <div class="slider-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h3 style="color: #222222;">Buy and Sell media content via fully secured Smart Contracts backed by ESCROW</h3>
                    <hr class="section-divider">

                    <!-- Image Slider -->

                    <!-- end of slider-container -->
                    <!-- end of image slider -->

                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of slider-1 -->
    <!-- end of customers -->


    <!-- Introduction -->
    <div id="intro" class="basic-1" style="padding-top: 0px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <img class="img-fluid" src="assets/images/Platform.png" alt="alternative">
                </div> <!-- end of col -->
                <div class="col-lg-5">
                    <div class="text-container" style="margin-top: 0px;">
                        <h2>Platform</h2>
                        <div class="basic-3" style="padding-top: 0px; padding-bottom: 0px;">
                            <div  class="media-body"><b>Host and Manage</b></div>
                            <p style=" margin-bottom: 10px;">Host and manage approvals in the backend and act as the master admin managing both “Seller” & “Buyer” while also managing the communication & notifications templates</p>
                            <div class="media-body"><b>Support</b></div>
                            <p style=" margin-bottom: 10px;">Provide L1 support to “Seller” & “Buyer” while also overseeing the analytics of “Seller” & “Buyer” in a combined dashboard</p>
                            <div class="media-body"><b>Help Desk</b></div>
                            <p style=" margin-bottom: 10px;">Handle the “Chat” and “Ticket” interface and also manages the responsibility to release payments to the seller.</p>
                            <div class="media-body"><b>Invoicing</b></div>
                            <p style=" margin-bottom: 10px;">View/ manage/ generate invoices</p>
                            <div class="media-body"><b>Contracting</b></div>
                            <p style=" margin-bottom: 10px;">Easy hassle free contracting between parties</p>
                            
                        </div>
                        <!-- <a class="btn-solid-reg" routerLink="/account/register-email">Sign up</a> -->
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
    <!-- end of introduction -->

     <!-- Introduction -->
     <div id="intro" class="basic-1" style="padding-top: 0px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-5">
                    <div class="text-container" style="margin-top: 0px;">
                        <h2>Buyer</h2>
                        <div class="basic-3" style="padding-top: 0px; padding-bottom: 0px;">
                            <div  class="media-body"><b>Offers</b></div>
                            <p>Create Unlimited offers and communicate with the Sellers and get notifications when Seller respond to your offer.</p>
                            <div  class="media-body"><b>Search</b></div>
                            <p>Search Thousands of content Titles from different Genres, categories and languages.</p>
                            <div  class="media-body"><b>Chat</b></div>
                            <p>Chat or email the seller directly from the platform anonymously.</p>
                            <div  class="media-body"><b>Buy Back</b></div>
                            <p>7-day buyback policy if the content is not as per the requirement or standard.</p>
                            <div  class="media-body"><b>Transaction</b></div>
                            <p>When interested, the buyer triggers a smart-contract request to the seller and upon agreed payment, the content link(s) are exchanged with the buyer via email.</p>

                        </div>
                        <!-- <a class="btn-solid-reg" routerLink="/account/register-email">Sign up</a> -->
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-7">
                    <img class="img-fluid" src="assets/images/verification-img.png" alt="alternative">
                </div> <!-- end of col -->
               
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
    <!-- end of introduction -->

     <!-- Introduction -->
     <div id="intro" class="basic-1" style="padding-top: 0px;">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <img class="img-fluid" src="assets/images/profile-img.png" alt="alternative">
                </div> <!-- end of col -->
                <div class="col-lg-5">
                    <div class="text-container" style="margin-top: 0px;">
                        <h2>Seller</h2>
                        <div class="basic-3" style="padding-top: 0px; padding-bottom: 0px;">
                            <div  class="media-body"><b>Content</b></div>
                            <p>Multi-language content repository with English metadata to start. Ability to upload the content at any point of the time 24x7x365.</p>
                            <div  class="media-body"><b>Geo-Tagging</b></div>
                            <p>The content can/may be geo-specific based on the available license with the seller.</p>
                            <div  class="media-body"><b>Publishing</b></div>
                            <p>Publish and unpublish the content uploaded on the platform manually or automatically.</p>
                            <div  class="media-body"><b>Metadata & Content Managment</b></div>
                            <p>Ingest content with detailed metadata option with bulk import capabilities to seamlessly ingest and showcase the content.</p>
                            <div  class="media-body"><b>Transaction</b></div>
                            <p>Chat/send and receive email/docs, images, and or negotiate with the buyers anonymously through the platform.</p>
                        </div>
                        <!-- <a class="btn-solid-reg" routerLink="/account/register-email">Sign up</a> -->
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-1 -->
    <!-- end of introduction -->



    <!-- Features -->
    <div id="features" class="cards-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="h2-heading">Content Marketplace</h2>
                    <p class="p-heading">Complete journey of the content licensing market place</p>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
            <div class="row">
                <div class="col-lg-12">

                     <!-- Card -->
                     <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon">
                                <span class="far fa-comments purple"></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Create and List</h4>
                            <p>Register: Create a free account / signup.</p>
                            <p>Build your listing: Upload and build your content library for sales.</p>
                            <p>Drive sales: sell your content to anyone, anywhere in the world</p>
                            <!-- <a class="read-more no-line" href="article.html">Learn more <span
                                    class="fas fa-long-arrow-alt-right"></span></a> -->
                        </div>
                    </div>
                    <!-- end of card -->

                    <!-- Card -->
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon">
                                <span class="fas fa-headphones-alt green"></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Smart Search</h4>
                            <p>Search enabled with meta descriptions </p>
                            <p>Build your selection across genres, regions, cast, directors, producers, and much more</p>
                            <!-- <a class="read-more no-line" href="article.html">Learn more <span
                                    class="fas fa-long-arrow-alt-right"></span></a> -->
                        </div>
                    </div>
                    <!-- end of card -->

                    <!-- Card -->
                    <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon">
                                <span class="far fa-clipboard blue"></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Negotiate</h4>
                            <p>Multiple tools for driving engagement between buyers and sellers - Chat, video conf, audio conf, message board, Slack</p>
                            <p>100% record keeping option for all workflow and conversations for audit and transparency purpose</p>
                            <p>Ability to export all records as an email </p>
                            <!-- <a class="read-more no-line" href="article.html">Learn more <span
                                    class="fas fa-long-arrow-alt-right"></span></a> -->
                        </div>
                    </div>
                    <!-- end of card -->

                     <!-- Card -->
                     <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon">
                                <span class="far fa-clipboard blue"></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Smart Contract</h4>
                            <p>Upload your own contract</p>
                            <p>Use predefined templates if you don’t have any standard contract document</p>
                            <p>Build your own using our standard template by customizing it as per your need</p>
                            <!-- <a class="read-more no-line" href="article.html">Learn more <span
                                    class="fas fa-long-arrow-alt-right"></span></a> -->
                        </div>
                    </div>
                    <!-- end of card -->

                     <!-- Card -->
                     <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon">
                                <span class="far fa-clipboard blue"></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Automated Invoice</h4>
                            <p>Raise PO from within the system</p>
                            <p>Use our system default templates if you don’t have any</p>
                            <p>Upload your own templates if you have</p>
                            <p>Inbuild Tax compliant algorithm</p>
                            <!-- <a class="read-more no-line" href="article.html">Learn more <span
                                    class="fas fa-long-arrow-alt-right"></span></a> -->
                        </div>
                    </div>
                    <!-- end of card -->

                     <!-- Card -->
                     <div class="card">
                        <div class="card-icon-wrapper">
                            <div class="card-icon">
                                <span class="far fa-clipboard blue"></span>
                            </div>
                        </div>
                        <div class="card-body">
                            <h4 class="card-title">Escrow</h4>
                            <p>Only platform in the content licensing market place that offers fully secure ESCROW facility for both buyers and sellers</p>
                            <!-- <a class="read-more no-line" href="article.html">Learn more <span
                                    class="fas fa-long-arrow-alt-right"></span></a> -->
                        </div>
                    </div>
                    <!-- end of card -->

                    

                   

                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of cards-1 -->
    <!-- end of features -->


    <!-- Details 1 -->
    <div id="details" class="basic-2">
        <div class="container">
            <div class="row">
                <div class="col-lg-9">
                    <div class="intro">
                        <h2>Start a successful business Journey in no more than 3 easy steps with Connekkt</h2>
                        <p>To build a solid foundation for your online Marketplace you need a strong Showcasing and a bulletproof strategy. You can rely on our experience to create a beautiful and efficient online Marketplace that offers benefits for both the content reach and deal closure support
                        </p>
                    </div> <!-- end of intro -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
            <div class="row">
                <div class="col-lg-7">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/Create-&-host-content.png" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
                <div class="col-lg-5">
                    <div class="text-container">
                        <div class="section-title">Step 1</div>
                        <h2 class="m-0">Create & host content  </h2>
                        <p class="pl-1"> Your content on paid Connekkt account</p>
                        <!-- <a class="btn-solid-reg popup-with-move-anim" routerLink="/account/register-email">Create Account Now</a> -->
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-2 -->
    <!-- end of details 1 -->


    <!-- Details Lightbox -->
    <!-- Lightbox -->
    <div id="details-lightbox" class="lightbox-basic zoom-anim-dialog mfp-hide">
        <div class="row">
            <button title="Close (Esc)" type="button" class="mfp-close x-button">×</button>
            <div class="col-lg-8">
                <div class="image-container">
                    <img class="img-fluid" src="assets/landing/images/details-lightbox.jpg" alt="alternative">
                </div> <!-- end of image-container -->
            </div> <!-- end of col -->
            <div class="col-lg-4">
                <h3>Goals Setting</h3>
                <hr>
                <p>The app can easily help you track your personal development evolution if you take the time to set it
                    up.
                </p>
                <h4>User Feedback</h4>
                <p>This is a great app which can help you save time and make your live easier. And it will help improve
                    your
                    productivity.</p>
                <ul class="list-unstyled li-space-lg">
                    <li class="media">
                        <i class="fas fa-chevron-right"></i>
                        <div class="media-body">Splash screen panel</div>
                    </li>
                    <li class="media">
                        <i class="fas fa-chevron-right"></i>
                        <div class="media-body">Statistics graph report</div>
                    </li>
                    <li class="media">
                        <i class="fas fa-chevron-right"></i>
                        <div class="media-body">Events calendar layout</div>
                    </li>
                    <li class="media">
                        <i class="fas fa-chevron-right"></i>
                        <div class="media-body">Location details screen</div>
                    </li>
                    <li class="media">
                        <i class="fas fa-chevron-right"></i>
                        <div class="media-body">Onboarding steps interface</div>
                    </li>
                </ul>
                <a class="btn-solid-reg mfp-close page-scroll" href="sign-up.html">Sign Up</a> <button
                    class="btn-outline-reg mfp-close as-button" type="button">Back</button>
            </div> <!-- end of col -->
        </div> <!-- end of row -->
    </div> <!-- end of lightbox-basic -->
    <!-- end of lightbox -->
    <!-- end of details lightbox -->


    <!-- Details 2 -->
    <div class="basic-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <div class="text-container">
                        <div class="section-title">Step 2</div>
                        <h2>Drive Sales , Enable Smart Search & Negotiate</h2>
                        <p>To sell your content to buyers across the globe</p>
                        <p>by adding meta-descriptions, content categories, regions, and more.</p>
                        <p>with buyers and sellers via chat, video/audio conference, Slack and more, with 100% record keeping option for workflows & conversations for audit & transparency.
                        </p>
                        
                    </div> <!-- end of text-container -->
                </div> <!-- end of col -->
                <div class="col-lg-6">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/images/2678409.jpg" alt="alternative">
                    </div> <!-- end of image-container -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of basic-3 -->
    <!-- end of details 2 -->


    <!-- Details 3 -->

    
    <!-- <div class="basic-4">
        <div class="container">
            <div class="row">
                <div class="col-lg-7">
                    <div class="image-container">
                        <img class="img-fluid" src="assets/landing/images/details-3.png" alt="alternative">
                    </div> 
                </div>
                <div class="col-lg-5">
                    <div class="text-container">
                        <div class="section-title">Step 3</div>
                        <h2>Transact</h2>
                        <p>Sell content across the world via Blockchain</p>
                        <a class="btn-solid-reg" routerLink="/account/register-email">Sign up now</a>
                    </div>
                </div>
            </div>
        </div> 
    </div>  -->
    
    <!-- end of basic-4 -->
    <!-- end of details 3 -->


   


    <!-- Newsletter -->
    <div class="form-1">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h2 class="h2-heading">Subscribe And Follow Us</h2>
                    <p class="p-heading">Be part of the story and follow us on Twitter via <a
                            href="#your-link">name</a>
                        and subscribe to the newsletter for news and updates about our workshops</p>
                </div> <!-- end of col -->
            </div> <!-- end of row -->
            <div class="row">
                <div class="col-lg-12">

                    <!-- Newsletter Form -->
                    <form>
                        <div class="form-group mail">
                            <input type="email" class="form-control-input" id="nemail" required>
                            <label class="label-control" for="nemail">Email address</label>
                            <div class="help-block with-errors"></div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="form-control-submit-button">Subscribe</button>
                        </div>
                        <div class="form-message">
                            <div id="nmsgSubmit" class="h3 text-center hidden"></div>
                        </div>
                    </form>
                    <!-- end of newsletter form -->

                </div> <!-- end of col -->
            </div> <!-- end of row -->
        </div> <!-- end of container -->
    </div> <!-- end of form-1 -->
    <!-- end of newsletter -->


    <!-- Footer -->
    <div class="footer">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="footer-col first">
                        <h6>About Connekkt Media</h6>
                        <p class="p-small">Blockchain powered Video/Audio Content Rights Marketplace.
                            World’s only Assisted sales platform for Digital Media Content Rights
                        </p>
                    </div> <!-- end of footer-col -->
                    <div class="footer-col second">
                        <h6>Useful Links</h6>
                        <ul class="list-unstyled li-space-lg p-small">
                            <li><a href="terms.html">Terms & Conditions</a><br/> <a href="privacy.html">Privacy
                                    Policy</a> <br><a routerLink="/faqs">FAQs</a> </li>
                            <!-- <li>Useful: <a href="#">Colorpicker</a>, <a href="#">Icon Library</a>, <a
                                    href="#">Illustrations</a></li>
                            <li>Menu: <a class="page-scroll" href="#header">Home</a>, <a class="page-scroll"
                                    href="#intro">Intro</a>, <a class="page-scroll" href="#features">Features</a>, <a
                                    class="page-scroll" href="#details">Details</a></li> -->
                                
                        </ul>
                    </div> <!-- end of footer-col -->
                    <div class="footer-col third">
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-facebook-f fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-pinterest-p fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="#your-link">
                                <i class="fas fa-circle fa-stack-2x"></i>
                                <i class="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
                        <p class="p-small">We would love to hear from you <a
                                href="mailto:support@connekkt.media"><strong>support@connekkt.media</strong></a>
                                <br/><a
                                    href="tel:+4407466 31893"><strong>+44 07466 331893</strong></a></p>

                    </div> <!-- end of footer-col -->
                </div> <!-- end of col -->
            </div> <!-- end of row -->

             <!-- Copyright -->
    <div class="copyright">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p class="p-small">Copyright © <a href="#your-link">Connekkt Media</a></p>
                </div> <!-- end of col -->
            </div> <!-- enf of row -->
        </div> <!-- end of container -->
    </div> <!-- end of copyright -->
    
        </div> <!-- end of container -->
    </div> <!-- end of footer -->
    <!-- end of footer -->

   
</body>
