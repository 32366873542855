import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ChatsubjectService {

  myMethod$: Observable<any>;
  // myMethod2$: Observable<any>;
  private myMethodSubject = new Subject<any>();
  // private myMethodSubject2 = new Subject<any>();

  chatSub = new Subject<any>();
  chatSub2 = new Subject<any>();
  constructor(private http: HttpClient) {
    this.myMethod$ = this.myMethodSubject.asObservable();
    // this.myMethod2$ = this.myMethodSubject2.asObservable();
  }
  myMethod(data) {
    // console.log(data); // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.myMethodSubject.next(data);
  }
  // myMethod2(data) {
  
  //   this.myMethodSubject2.next(data);
  // }

  pushChatItem(item: any) {
    this.chatSub.next(item);

  }

  getChatItem(): Observable<any> {
    return this.chatSub.asObservable();
  }
  clearChatItem() {
    this.chatSub.next({});
  }
}
