import { Component, OnInit, AfterViewInit, Inject, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LanguageService } from '../../core/services/language.service';
import { Subscription, timer } from 'rxjs';
import { EventService } from '../../core/services/event.service';
import { AuthenticationService } from '../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../core/services/authfake.service';

import { DOCUMENT } from '@angular/common';
import { map } from 'rxjs/operators';
import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { SELLER_MENU } from './seller-menu';
import { BUYER_MENU } from './buyer-menu';
import { environment } from '../../../environments/environment';
import { SellerService } from "src/app/core/services/seller.service";
import { ChatService } from 'src/app/core/services/chat.service';
import { HttpClient } from '@angular/common/http';
import { ChatsubjectService } from 'src/app/core/services/chatsubject.service';
import { ChatlogsService } from 'src/app/@shared/chatlogs/chatlogs.service';
@Component({
  selector: 'app-horizontaltopbar',
  templateUrl: './horizontaltopbar.component.html',
  styleUrls: ['./horizontaltopbar.component.scss']
})

/**
 * Horizontal Topbar and navbar specified
 */
export class HorizontaltopbarComponent implements OnInit, AfterViewInit {
  uid = localStorage.getItem('uid');
  user;
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  extra = true;
  menuItems = [];
  totalUnreadList = [];
  subscription: Subscription;
  timerSubscription: Subscription;
  type:any;
  types:any;

  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];
  cont: any;
  continuin: any;
  item: any;
  @Input() uidC: any;
  data1: any;
  data2: any;

  // tslint:disable-next-line: max-line-length
  constructor(@Inject(DOCUMENT) private document: any, private chat: ChatlogsService, private http: HttpClient, private chatSub: ChatsubjectService, private router: Router, private eventService: EventService, private authService: AuthenticationService, private authFackservice: AuthfakeauthenticationService,
    public languageService: LanguageService, private ss: SellerService, private chatServ: ChatService,
    // tslint:disable-next-line: variable-name
    public _cookiesService: CookieService) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.activateMenu();
      }
    });

    this.chatSub.myMethod$.subscribe((data) => {

      this.data1 = data; // And he have data here too!

    });

    // this.chatSub.myMethod2$.subscribe((data) => {
    //     // alert(data)
    //   this.data2 = data; // And he have data here too!

    // });
    
    // this.chatSub.getChatItem().subscribe((datas:any)=>{
    //  alert(datas.text)
    // })


    // this.socketConnectionMessagess();

  }

  ngOnInit(): void {

    this.buyernotifcation()
  //   setInterval(() => {
  //     this.buyernotifcation
  // }, 1000);
    // this.chatServ.getSocketMessages().subscribe(data => {
    //   console.log(data)
    //   if((data==='start_chat')){

    //     this.notifcation()
    //   }
    // })

    // this.notifcation();
    this.element = document.documentElement;
    this.user = JSON.parse(localStorage.getItem('clpUser'));

    this.initialize();
    // 
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/spain.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    // this.timerSubscription = timer(0, 60000).pipe( 
    //   map(() => { 
    //     this.notifcation(); // load data contains the http request 
    //   }) 
    // ).subscribe(); 
    // this.connection()
  }

  

  buyernotifcation() {
    
    // alert("lop")
    this.ss.getBuyerNotiCount(this.uid).subscribe((res: any) => {
      if (res.code === 1) {
        // this.totalUnreadList = res.result[0].totalunread; 
        this.data2 = res.result[0].totalunread;
        console.log(this.data2)
        // this.chatsub.myMethod2(this.data2)
      }
    })
  }


  
  // socketConnectionMessagess() {
  //   this.subscription = this.chatServ.getSocketMessages().subscribe((data:any)=>{
  //     console.log(data)
  //   })


  // if (data === 'start_chat') {
  //   console.log(data)
  //   this.notifcation();

  // }

  // }
  // connection(){
  //   return this.http.get(`https://belive.multitvsolution.com:8030/bigboyget?token=Jamesbond_007&msg=start_chat`).subscribe();
  // }
  // ngOnDestroy(): void { 
  //   this.timerSubscription.unsubscribe(); 
  // }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Logout the user
   */
  logout() {
    // if (environment.defaultauth === 'firebase') {
    //   this.authService.logout();
    // } else {
    //   this.authFackservice.logout();
    // }
    this.chatServ.removeSocketConnection()
    this.router.navigate(['/account/login']);
    localStorage.clear();
  }
  gotoProfile() {
    this.router.navigate(['/contacts/profile']);
  }
  gotoChangePassword() {
    // localStorage.clear();
    this.router.navigate(['/account/change-password'])
  }
  // notifcation() {
  //   // alert("lop")
  //   this.ss.getNotifications(this.uid).subscribe((res: any) => {
  //     if (res.code === 1) {
  //       // this.totalUnreadList = res.result[0].totalunread; 
  //       this.data1 = res.result[0].totalunread;

  //       console.log(this.totalUnreadList)


  //     }
  //   })
  // }


  moveChatlogs() {


    localStorage.setItem('uid', JSON.parse(localStorage.getItem('clpUser')).uid)
    localStorage.setItem('type', JSON.parse(localStorage.getItem('clpUser')).type)


    this.type = localStorage.getItem('type');

    if (this.type == 2) {
      this.router.navigate(['seller/notification']);
    } else {
      // alert("buy")
      this.router.navigate(['buyer/notification']);
    }

  }
  
  gotoWatchList(){
    localStorage.setItem('uid', JSON.parse(localStorage.getItem('clpUser')).uid)

    this.types = JSON.parse(localStorage.getItem('clpUser')).type;

    // if (localStorage.clpUser == 2) {
    
    //   this.router.navigate(['seller/watchlist']);
    // } else {
      
    //   this.router.navigate(['buyer/watchlist']);
    // }

    if (this.types == 2) {
    
      this.router.navigate(['seller/watchlist']);
    } else {
      // alert("buy")
      this.router.navigate(['buyer/watchlist']);
    }

  }

  /**
   * On menu click
   */
  onMenuClick(event) {
    const nextEl = event.target.nextElementSibling;
    if (nextEl) {
      const parentEl = event.target.parentNode;
      if (parentEl) {
        parentEl.classList.remove("show");
      }
      nextEl.classList.toggle("show");
    }
    return false;
  }
  itemRemove(event) {
    if (event == 'CHAT') {
      localStorage.removeItem("content")
    }

  }
  ngAfterViewInit() {
    this.activateMenu();

  }

  /**
   * remove active and mm-active class
   */
  _removeAllClass(className) {
    const els = document.getElementsByClassName(className);
    while (els[0]) {
      els[0].classList.remove(className);
    }
  }

  /**
   * Togglemenu bar
   */
  toggleMenubar() {
    const element = document.getElementById('topnav-menu-content');
    element.classList.toggle('show');
  }

  /**
   * Activates the menu
   */
  private activateMenu() {

    const resetParent = (el: any) => {
      const parent = el.parentElement;
      if (parent) {
        parent.classList.remove('active');
        const parent2 = parent.parentElement;
        this._removeAllClass('mm-active');
        this._removeAllClass('mm-show');
        if (parent2) {
          parent2.classList.remove('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.remove('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove('active');
                const menuelement = document.getElementById("topnav-menu-content")
                if (menuelement !== null) {
                  if (menuelement.classList.contains('show'))
                    document
                      .getElementById("topnav-menu-content")
                      .classList.remove("show");
                }
              }
            }
          }
        }
      }
    };

    // activate menu item based on location
    const links = document.getElementsByClassName('side-nav-link-ref');
    let matchingMenuItem = null;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // reset menu
      resetParent(links[i]);
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (location.pathname === links[i]['pathname']) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add('active');
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add('active');
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add('active');
            const parent4 = parent3.parentElement;
            if (parent4) {
              parent4.classList.add('active');
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.add('active');
                const parent6 = parent5.parentElement;
                if (parent6) {
                  parent6.classList.add('active');
                }
              }
            }
          }
        }
      }
    }
  }

  /**
   * on settings button clicked from topbar
   */
  onSettingsButtonClicked() {
    document.body.classList.toggle('right-bar-enabled');
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  /**
   * Initialize
   */
  initialize(): void {
    let type = JSON.parse(localStorage.getItem('clpUser')).type;
    this.menuItems = (parseInt(type) === 2) ? SELLER_MENU : BUYER_MENU;
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

}
