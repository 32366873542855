import { MenuItem } from "./menu.model";

export const SELLER_MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: "Communication",
    //     icon: "bx-home-circle",
    //     link: "/seller/landing"
    // },
    {
        id: 1,
        label: "Order History",
        icon: "bx-home-circle",
        badge: {
            variant: 'danger',
            text: 'MENUITEMS.DASHBOARDS.BADGE',
        },
        link: "/seller/landing"
    },
    {
        id: 2,
        label: "MENUITEMS.CONTENT.LIST.LIST",
        icon: "bx-tone",
        link: "/seller/contentlist"
    },
    {
        id: 3,
        label: "MENUITEMS.CONTENT.LIST.UPLOAD",
        icon: "bx-customize",
        link: "/seller/contentupload"
    },
    // {
    //     id: 4,
    //     label: "Chat Logs",
    //     icon: "bx-collection",
    //     link: "/seller/chatlogs"
    // },
    {
        id: 4,
        label: "CHAT",
        icon: "bx-chat",
        link: "/seller/chat"
    },
    // {
    //     id: 4,
    //     label: "MENUITEMS.CONTENT.LIST.SEARCH",
    //     icon: "bx-collection",
    //     link: "/seller/contentlist"
    // },
    {
        id: 5,
        label: "Library",
        icon: "bx-file",
        subItems: [
            {
                id: 1,
                label: 'Video Library',
                link: '/seller/media-library',
                parentId: 5
            },
            {
                id: 2,
                label: 'Image Library',
                link: "/seller/image-library",
                parentId: 5
            },
            {
                id: 3,
                label: 'Library',
                link: "/seller/library",
                parentId: 5
            },
            // {
            //     id: 3,
            //     label: 'View Library',
            //     link: "/seller/view-library",
            //     parentId: 5
            // },
        ]
        
    },
     {
        id: 6,
        label: "Sales Support",
        icon: "bx-support",
        link: "/seller/sale-support"
    },
];
